import React from "react"
import Layout from "../components/layout"

export default function Home() {
 return (
    <Layout>
      Hallo    
    </Layout>
 )
}
